import { FC } from "react";
import Image from "next/image";
import { motion } from "framer-motion";
import { GLOBAL_CONFIG } from "@shared/constants";
import { useWindowSize } from "@dustlabs/web/hooks";
import { ProjectConfig } from "@shared/types";

const LandingView: FC = () => {
  const [winWidth] = useWindowSize();
  const mobileView = winWidth < 640;
  const tabletView = winWidth >= 640 && winWidth < 1024;
  const { OPENSEA } = GLOBAL_CONFIG as ProjectConfig;

  const CLUBS_IMAGES = [
    "/y00ts/n00uns-club.png",
    "/y00ts/fast-club.png",
    "/y00ts/halo-club.png",
    "/y00ts/degooats-club.png",
    "/y00ts/degolf-club.png",
    "/y00ts/cm-club.png",
    "/y00ts/blase-club.png",
    "/y00ts/hoodie-club.png",
  ];

  const firstPageY00tHeight = mobileView || tabletView ? 207 : 400,
    firstPageY00tWidth = mobileView || tabletView ? 640 : 900;

  const artPageHeight = mobileView ? 232 : 395,
    argPageWidth = mobileView ? 324 : tabletView ? 640 : 553;

  const fullpages = (
    <div className={`flex-col px-[24px] ${mobileView && "max-w-[385px]"}`}>
      <motion.div
        id="landingPage"
        className={`flex flex-col space-y-10 items-center relative`}
        {...GLOBAL_CONFIG.OPACITY_ANIMATION}
      >
        <div className="flex-col items-center text-[14px] text-center pt-40 lg:pt-32 lg-short:pt-20">
          <Image
            src="/y00ts/y00ts-logo.png"
            width={mobileView ? 197 : 400}
            height={mobileView ? 146 : 400}
            alt="y00ts logo"
          />
        </div>
        {mobileView ? (
          <div className={`flex-col justify-center w-screen absolute bottom-0`}>
            <div className="w-full">
              <Image
                src="/y00ts/y00ts-group.png"
                width={firstPageY00tWidth}
                height={firstPageY00tHeight}
                layout="responsive"
                objectFit="contain"
                alt="y00t-group"
                className="mx-auto"
              />
            </div>
            <div className="h-1 bg-black opacity-10 w-screen absolute -bottom-1" />
          </div>
        ) : (
          <div className={`flex justify-center absolute bottom-0`}>
            <div className={`flex justify-center lg:w-[1000px]`}>
              <div>
                <Image
                  src="/y00ts/y00ts-group.png"
                  height={firstPageY00tHeight}
                  width={firstPageY00tWidth}
                  alt="y00t-group"
                />
              </div>
            </div>
            <div className="h-1 bg-black opacity-10 w-screen absolute -bottom-1" />
          </div>
        )}
      </motion.div>
      <motion.div
        className={`flex-col space-y-10 justify-center w-full max-w-[400px] m-auto mt-52 md:mt-48`}
        {...GLOBAL_CONFIG.OPACITY_ANIMATION}
      >
        <div className="flex-col md:px-0 space-y-4 md:space-y-10">
          <div className="flex text-[20px] md:text-[23px] gap-2 items-center">
            <Image
              src="/y00ts/welcome-star.png"
              height={41}
              width={25}
              alt="welcome-star"
            />
            <p className="font-primary">Welcome.</p>
          </div>
          <div className="text-[23px] font-primary text-justify md:leading-[25.3px]">
            <p
              className={`text-[20px] md:text-[23px] leading-[22px] font-primary text-justify md:leading-[25.3px]`}
            >
              y00ts is a generative art project of 15,000 NFTs. It is our love
              letter to the Web3 community. Designed to be aesthetic. Engineered
              to be{" "}
              <span className="relative h-[30px] w-[80px] font-primary text-[20px] md:text-[23px]">
                functional
                <Image
                  className="absolute -bottom-0.5 right-0"
                  src="/y00ts/functional-scribble.svg"
                  height={50}
                  width={mobileView ? 110 : 120}
                  alt="functional-scribble"
                />
              </span>
              . Curated to contain the best community of builders and creators
              on the internet.
            </p>
          </div>
          <div className="flex-col text-[20px] md:text-[23px] space-y-3 pt-4 md:pt-0">
            <p className="md:pl-3 font-primary">Sincerely,</p>
            <Image
              src="/y00ts/delabs-sig.png"
              height={mobileView ? 39 : 51}
              width={mobileView ? 122 : 160}
              alt="delabs-sig"
            />
          </div>
        </div>
      </motion.div>
      <motion.div
        className={`flex-col space-y-10 justify-center mt-48 md:mt-64`}
        {...GLOBAL_CONFIG.OPACITY_ANIMATION}
      >
        <div className="flex-col -space-y-6">
          <div className="flex-col space-y-12">
            <div className="flex-col space-y-6 md:space-y-16">
              <div className={`flex-col space-y-14 md:space-y-16`}>
                <div className="flex text-[23px] items-center justify-center">
                  <Image
                    src="/y00ts/the-art-sig.png"
                    height={mobileView ? 77 : 160}
                    width={mobileView ? 187 : 380}
                    alt="the-art-sig"
                  />
                </div>
                <div
                  className={`lg:flex space-y-8 md:space-y-4 gap-4 overflow-visible`}
                >
                  <div
                    className={`flex relative m-auto md:m-none justify-center cursor-pointer`}
                  >
                    <a
                      className="flex gap-2 md:gap-3 md:-space-y-4"
                      href="https://www.figma.com/file/SaV5YVDYGGr93bDftfC9oN/y00ts-Artwork-Documentation?node-id=0%3A1&t=q5ZgtFBdAnolBB6b-0"
                      target="__blank"
                    >
                      <Image
                        src="/y00ts/art-image-group.png"
                        height={artPageHeight}
                        width={argPageWidth}
                        alt="the-art-group"
                      />
                    </a>
                  </div>
                  <div className="flex-col space-y-7 md:space-y-5  md:w-[600px] lg:w-[400px] md:pb-0 lg:pb-16 md:px-0 md:pt-8 lg:pt-44 m-auto lg:px-4">
                    <div className="-space-y-6 m-auto md:w-[600px] lg:w-[400px]">
                      <p
                        className={`font-primary leading-[22px] md:leading-[25.3px] text-[20px] md:text-[23px]`}
                      >
                        We spent months perfecting the artistic process behind
                        y00ts.
                      </p>
                    </div>
                    <div className="hidden md:flex md:flex-col -space-y-4 md:space-y-1 pb-6 md:pb-0 lg:pb-4 md:w-[600px] lg:w-[400px] m-auto">
                      <p
                        className={`font-primary leading-[22px] md:leading-[25.3px] text-[20px] md:text-[23px] `}
                      >
                        To learn more about the art
                        <span className="inline-block w-[340px]"></span>
                      </p>
                      <div className="flex gap-4 cursor-pointer m-auto md:w-full md:m-none">
                        <a
                          className="flex gap-2 md:gap-3 md:-space-y-4"
                          href="https://www.figma.com/file/SaV5YVDYGGr93bDftfC9oN/y00ts-Artwork-Documentation?node-id=0%3A1&t=q5ZgtFBdAnolBB6b-0"
                          target="__blank"
                        >
                          <Image
                            src="/y00ts/click-here-button.png"
                            height={mobileView || tabletView ? 25 : 53}
                            width={mobileView || tabletView ? 117 : 249}
                            alt="click-here-button"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-center flex cursor-pointer">
                <a
                  className="flex gap-2 md:gap-3 md:-space-y-4"
                  href="https://www.figma.com/file/SaV5YVDYGGr93bDftfC9oN/y00ts-Artwork-Documentation?node-id=0%3A1&t=q5ZgtFBdAnolBB6b-0"
                  target="__blank"
                >
                  <Image
                    src="/y00ts/art-grouping.png"
                    height={mobileView ? 240 : 800}
                    width={mobileView ? 380 : tabletView ? 640 : 940}
                    alt="y00t-grouping"
                  />
                </a>
              </div>
              <div className="flex-col md:hidden -space-y-4 pb-6 m-auto">
                <p className={`font-primary leading-[22px] text-[20px]`}>
                  To learn more about the art
                  <span className="inline-block w-[340px]"></span>
                </p>
                <div className="flex gap-4 cursor-pointer m-auto">
                  <a
                    className="flex gap-2"
                    href="https://www.figma.com/file/SaV5YVDYGGr93bDftfC9oN/y00ts-Artwork-Documentation?node-id=0%3A1&t=q5ZgtFBdAnolBB6b-0"
                    target="__blank"
                  >
                    <Image
                      src="/y00ts/click-here-button.png"
                      height={25}
                      width={117}
                      alt="click-here-button"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div
        className={`flex-col space-y-10 justify-center md:max-w-[800px] mx-auto mt-32 md:mt-80`}
        {...GLOBAL_CONFIG.OPACITY_ANIMATION}
      >
        <div className="flex-col space-y-10">
          <div className="flex gap-2 items-center justify-center">
            <Image
              src="/y00ts/primary-colors-wheel.png"
              height={mobileView ? 53 : 180}
              width={mobileView ? 47 : 160}
              alt="primary-colors"
            />
            <Image
              src="/y00ts/rewards-logo.png"
              height={mobileView ? 77 : 170}
              width={mobileView ? 163 : 380}
              alt="rewards-logo"
            />
          </div>
          <div className="text-center">
            <p className="font-primary text-[18px] md:text-[23px]">
              {`Every y00t earns points every minute that it’s staked.`}
            </p>
            <p className="font-primary text-[19px] md:text-[23px]">
              Use rewards to redeem{" "}
              <span className="relative text-[19px] md:text-[23px] font-primary h-[30px] w-[100px]">
                amazing
                <Image
                  className="absolute -bottom-3 right-0"
                  src="/y00ts/squiggle-under-amazing.svg"
                  height={50}
                  width={80}
                  alt="squiggle-under-amazing"
                />
              </span>{" "}
              prizes.
            </p>
          </div>
          <div className="flex gap-5 md:gap-6 lg:gap-16 justify-center">
            <div className="w-[53px] h-[152px] md:h-[384px] md:w-[180px] bg-[#FFD438] rounded-[11px] md:rounded-[32px] border-black border-[.38px] md:border mt-6 md:mt-0" />
            <Image
              className=""
              src="/y00ts/rewards-y00t.png"
              height={mobileView || tabletView ? 365 : 545}
              width={mobileView ? 190 : tabletView ? 280 : 280}
              alt="rewards-y00t"
            />
            <div className="space-y-12 md:space-y-6 my-auto mt-16 md:mt-8">
              <div className="h-[170px] w-[53px] md:h-[320px] md:w-[180px] bg-[#FF4A3F] rounded-[11px] md:rounded-[32px] border-black border-[.38px] md:border" />
              <div className="h-[50px] w-[53px] md:h-[162px] md:w-[180px] bg-[#5B7FF8] rounded-[11px] md:rounded-[32px] border-black border-[.38px] md:border" />
            </div>
          </div>
          <div className={`flex justify-center md:pr-6 w-full`}>
            <a href="/staking">
              <button className="border border-[#000000] py-2 md:py-2 px-4 md:px-[4.5rem] font-primary text-[20px] md:text-[23px] bg-[#1D1D1F] text-white whitespace-nowrap">
                Start earning y00tPoints
              </button>
            </a>
          </div>
        </div>
      </motion.div>
      <motion.div
        className={`flex-col space-y-10 justify-center max-w-[850px] mx-auto justify-center mt-48 md:mt-64`}
        {...GLOBAL_CONFIG.OPACITY_ANIMATION}
      >
        <div className="flex-col space-y-10 md:space-y-16">
          <div className="flex-col -space-y-10 md:-space-y-14 mx-auto">
            <Image
              className="mx-auto"
              src="/y00ts/y00ts-logo.png"
              height={mobileView ? 120 : 200}
              width={mobileView ? 162 : 200}
              alt="y00ts-logo"
            />
            <Image
              className="pl-24 mx-auto"
              src="/y00ts/clubs.png"
              height={200}
              width={200}
              alt="clubs-logo"
            />
          </div>
          <div className="flex-col space-y-6 md:space-y-16">
            <div className="flex-col text-[18px] gap-2 justify-center -space-y-12 text-center">
              <p className="font-primary text-[20px] md:text-[23px] max-w-[600px] m-auto">
                We have over 50 highly active clubs within our community, and
                the number is growing every day. Every y00t comes with all of
                the IP and branding rights, to empower our holders to create
                using our brand.
              </p>
            </div>
            <div className="flex flex-wrap justify-center lg:justify-between gap-4">
              {CLUBS_IMAGES.map((path) => (
                <Image
                  key={path}
                  src={path}
                  height={mobileView ? 212 : 200}
                  width={mobileView ? 153 : 200}
                  alt={`${path}-logo`}
                />
              ))}
            </div>
          </div>
          <div className="flex-col justify-center text-center space-y-8">
            <div className="">
              <a
                className="font-primary text-[20px] md:text-[23px]"
                href="/clubs"
              >
                view all
              </a>
            </div>
            <div className="justify-center pb-36">
              <a href={OPENSEA} target="__blank">
                <button className="border border-[#000000] py-2 px-6 md:px-8 font-primary text-[18px] md:text-[23px] bg-[#1D1D1F] text-white whitespace-nowrap">
                  Buy a y00t - join the club
                </button>
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );

  return (
    <div className="w-full relative flex-col space-y-8">
      <div
        className={`${!mobileView && "relative"} 
      justify-center items-center flex`}
      >
        {fullpages}
      </div>
      <div className="absolute bottom-0 right-0 w-full flex justify-end">
        <Image
          src="/y00ts/y00ts-teaser.png"
          width={640}
          height={350}
          alt="teaser"
        />
      </div>
    </div>
  );
};

export default LandingView;
