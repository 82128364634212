import React, { FC, HTMLAttributes } from "react";
import Image from "next/image";
import Link from "next/link";
import { GLOBAL_CONFIG } from "@shared/constants";
import { NftMetadata } from "@shared/types";
import { OrdinalHubIcon } from "@shared/components";
import { ORDINALS_MAP } from "@explorer/constants";

interface Props extends HTMLAttributes<HTMLDivElement> {
  nftMetadata: NftMetadata;
  isBtc?: boolean;
}

const NftName: FC<Props> = ({
  nftMetadata,
  className,
  isBtc,
  ...componentProps
}: Props) => {
  return (
    <div
      className={`w-full flex justify-between items-center bg-lightSurface py-3 px-4 rounded-lg ${className}`}
      {...componentProps}
    >
      <div className="flex flex-col">
        <div className="font-bold text-2xl">{nftMetadata.name}</div>
        {isBtc && (
          <Link
            href={`https://ordinalhub.com/inscription/${
              ORDINALS_MAP.find(
                (entry) => entry["DeGod Id"] === nftMetadata.id + 1
              )?.["Inscription ID"]
            }`}
            rel="noopener noreferrer"
            target="_blank"
            className="mt-1"
          >
            <OrdinalHubIcon height={20} />
          </Link>
        )}
      </div>
      {nftMetadata.rank && (
        <div className="border border-fontSecondary rounded-full py-1 px-3 text-xs text-fontSecondary">
          Rank #{nftMetadata.rank}
        </div>
      )}
    </div>
  );
};

export default NftName;
