import React, { SyntheticEvent, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { Select, SelectOption } from "@dustlabs/web/core";
import { useDeId } from "@dustlabs/profiles/core";
import { ProfileHeldCollection } from "@dustlabs/profiles/types";
import { useNftMetadata, useOrdinalsMetadata } from "@deid/helpers";
import { GLOBAL_CONFIG } from "@shared/constants";
import { Card } from "@shared/components";
import { ORDINALS_MAP } from "@explorer/constants";
import { NftCard } from "@deid/components";

const MyNftsView = () => {
  const { holdings, isLoadingProfile } = useDeId();
  const nftMetadata = useNftMetadata();
  const ordinalsMetadata = useOrdinalsMetadata();

  const options = [
    {
      key: "default",
      label: "Default",
    },
    {
      key: "asc",
      label: "ID (Ascending)",
    },
    {
      key: "desc",
      label: "ID (Descending)",
    },
    {
      key: "rank-asc",
      label: "Rank (Ascending)",
    },
    {
      key: "rank-desc",
      label: "Rank (Descending)",
    },
    {
      key: "btc-first",
      label: "Chain (BTC, ETH)",
    },
  ];

  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<SelectOption>(options[0]);

  const handleSort = (_: SyntheticEvent | null, selected: string) => {
    setSort(options.find((option) => selected === option.key) ?? options[0]);
  };

  const evmHoldings = useMemo(
    () =>
      holdings
        .find(
          (heldCollection: ProfileHeldCollection) =>
            heldCollection.contract === process.env.NEXT_PUBLIC_NFT_CONTRACT
        )
        ?.tokens.map((token) => token.tokenId) ?? [],
    [holdings]
  );

  const btcHoldings = useMemo(
    () =>
      holdings
        .find(
          (heldCollection: ProfileHeldCollection) =>
            heldCollection.contract === "BTC DeGods"
        )
        ?.tokens.map((token) => token.tokenId) ?? [],
    [holdings]
  );

  const content = useMemo(
    () => [
      ...evmHoldings
        .filter((tokenId) => (tokenId + 1).toString().includes(search))
        .sort((a, b) => {
          const metadataA = nftMetadata.find(
            (metadata) => metadata.data?.id === a
          )?.data;
          const metadataB = nftMetadata.find(
            (metadata) => metadata.data?.id === b
          )?.data;

          if (!metadataA?.rank || !metadataB?.rank) return 0;

          return sort.key === "asc"
            ? a - b
            : sort.key === "desc"
            ? b - a
            : sort.key === "rank-asc"
            ? metadataA?.rank - metadataB?.rank
            : sort.key === "rank-desc"
            ? metadataB?.rank - metadataA?.rank
            : 0;
        })
        .map((tokenId: number, index: number) => {
          const metadataQuery = nftMetadata.find(
            (metadata) => metadata.data?.id === tokenId
          );
          const metadata = metadataQuery?.data;

          if (!metadata) return null;

          const currentImageSeason = metadata?.image.includes("s3")
            ? "SIII"
            : metadata?.image.includes("dead")
            ? "SII"
            : "SI";

          const currentPerspective = metadata?.image.includes("zoomed")
            ? "(DeCropped)"
            : "";

          return (
            <NftCard
              key={index}
              nftMetadata={metadata}
              tag={`${currentImageSeason} ${currentPerspective}`}
              callback={async () => {
                await metadataQuery.refetch();
              }}
            />
          );
        }),
      ...btcHoldings
        .filter((inscriptionNumber) => {
          const tokenId = ORDINALS_MAP.find(
            (current) => current["Inscription Number"] === inscriptionNumber
          )?.["DeGod Id"];

          if (!tokenId) return false;

          return (
            inscriptionNumber.toString().includes(search) ||
            (tokenId + 1)?.toString().includes(search)
          );
        })
        .sort((a, b) => {
          const metadataA = nftMetadata.find(
            (metadata) => metadata.data?.id === a
          )?.data;
          const metadataB = nftMetadata.find(
            (metadata) => metadata.data?.id === b
          )?.data;

          if (!metadataA?.rank || !metadataB?.rank) return 0;

          return sort.key === "asc"
            ? a - b
            : sort.key === "desc"
            ? b - a
            : sort.key === "rank-asc"
            ? metadataA?.rank - metadataB?.rank
            : sort.key === "rank-desc"
            ? metadataB?.rank - metadataA?.rank
            : 0;
        })
        .map((inscriptionNumber: number, index: number) => {
          const tokenId = ORDINALS_MAP.find(
            (current) => current["Inscription Number"] === inscriptionNumber
          )?.["DeGod Id"];

          if (!tokenId) return null;

          const metadata = ordinalsMetadata.find(
            (metadata) => metadata.data?.id === tokenId - 1
          )?.data;

          if (!metadata) return null;
          metadata.name = `Inscription ${inscriptionNumber}`;
          metadata.image = `https://static.degods.com/ordinals/${
            tokenId - 1
          }.png`;

          return (
            <NftCard
              key={evmHoldings.length + index}
              nftMetadata={metadata}
              tag="BTC"
              isBtc
            />
          );
        }),
    ],
    [evmHoldings, btcHoldings, search, sort, nftMetadata, ordinalsMetadata]
  );

  return (
    <motion.div
      {...GLOBAL_CONFIG.OPACITY_ANIMATION}
      className="flex flex-col items-center pt-8"
    >
      <div className="flex flex-col w-full sm:w-3/4 lg:w-2/3 min-w-0 lg:min-w-[1024px] px-6 md:px-0">
        <div className="w-full flex justify-between items-center py-6 border-b border-lightBorder">
          <div className="flex flex-col">
            <div className="font-extrabold text-2xl md:text-3xl mb-1">
              My Holdings
            </div>
            <div className="text-fontSecondary">
              View your {GLOBAL_CONFIG.ORG_NAME} across all your wallets
            </div>
          </div>

          {holdings.length > 0 && (
            <div className="hidden md:flex flex-col gap-1">
              <div className="text-sm text-fontSecondary uppercase">
                Total {GLOBAL_CONFIG.ORG_NAME}
              </div>
              <div className="text-base lg:text-2xl font-extrabold">
                {evmHoldings.length + btcHoldings.length}/
                {GLOBAL_CONFIG.TOTAL_NFTS_ALL_CHAINS}
              </div>
            </div>
          )}
        </div>

        <div className="w-full pt-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-center lg:justify-between gap-5 pb-8">
            <div className="col-span-1 md:col-span-2">
              <input
                placeholder="Search ID"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="w-full h-10 px-3 bg-lightCard text-base text-lightFontPrimary placeholder-fontSecondary rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <div className="hidden lg:block col-span-0 lg:col-span-1" />
            <div className="col-span-1 md:col-span-2 lg:col-span-1 flex justify-end z-20">
              <Select
                value={sort.key}
                onChange={handleSort}
                className="w-full [&_.selectBase]:h-10 [&_.selectBase]:border-none  [&_.selectBase]:bg-lightCard [&_.selectBase]:rounded-lg [&_.selectPlaceholder]:text-fontSecondary [&_.selectPlaceholder]:opacity-100"
                options={options}
                customLabel={`Sort By: ${sort.label}`}
              />
            </div>
            {evmHoldings.length > 0 || btcHoldings.length > 0 ? (
              sort.key === "btc-first" ? (
                [
                  ...content.slice(evmHoldings.length),
                  ...content.slice(0, evmHoldings.length),
                ]
              ) : (
                content
              )
            ) : isLoadingProfile || nftMetadata.some((md) => md.isLoading) ? (
              Array.from(Array(4).keys()).map((index: number) =>
                renderSkeletonCard(index)
              )
            ) : (
              <Card className="p-3 bg-lightSurface !shadow-none text-lightFontPrimary rounded-lg">
                No {GLOBAL_CONFIG.ORG_NAME} found in de[id]
              </Card>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default MyNftsView;

const renderSkeletonCard = (key: number) => (
  <div
    key={key}
    className="!bg-lightBackground border border-lightBorder relative mb-2 lg:mb-5 rounded-2xl overflow-clip"
  >
    <div className="w-full aspect-square animate-pulse bg-slate-300"></div>
    <div className="flex justify-between gap-1 py-2 px-3">
      <div className="w-1/3 h-4 animate-pulse bg-slate-300 rounded"></div>
      <div className="w-1/3 h-4 animate-pulse bg-slate-300 rounded"></div>
    </div>
  </div>
);
