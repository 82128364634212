import { createContext } from "react";

export type PageOptions = "HOME" | "ITEM_DETAILS";

export interface ExplorerPageContext {
  page: PageOptions;
  togglePage: (newPage: PageOptions) => void;
}

export const ExplorerPageContext = createContext<ExplorerPageContext>({
  page: "HOME",
  togglePage: (newPage: PageOptions) => {},
});
