import { AttributeColors } from "@shared/types";

export const DEFAULTS = {
  CLUSTER: "Mainnet",
  DUST_TOKEN_ADDRESS: "DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ",
  HTTP_RPC: "https://degods.genesysgo.net/",
  METADATA_CDN_URL: "https://metadata.degods.com",
  DEGODS_API_URL: "https://api.degods.com/v1/",
};

export const TRAIT_DATA: AttributeColors = {
  background: {
    marshmallow: ["#F2F0EB", "", ""],
    vanilla_ice: ["#F0EADA", "", ""],
    solitary_star: ["#F3EBD6", "", ""],
    snow_white: ["#EFEBE7", "", ""],
    powder_puff: ["#F3E0D6", "", ""],
    phantom_green: ["#DCE4D7", "", ""],
    cannoli_cream: ["#F1F0E2", "", ""],
    buttercream: ["#EFE1CE", "", ""],
    bit_of_blue: ["#E2EAEB", "", ""],
    antique_white: ["#EEE5D3", "", ""],
  },
  fur: {
    chestnut: ["#5a3c29", "#946342", "#CB895C"],
    hazel: ["#825639", "#C28256", "#FFA970"],
    tumbleweed: ["#A8866C", "#C28256", "#FFE5D1"],
    eggnog: ["#A8866C", "#D4BA99", "#FFE5D1"],
    wheat: ["#A89383", "#E7CBB5", "#FFECDE"],
    sand_dollar: ["#9E9286", "#DECDBD", "#FFF2E5"],
    bone: ["#99978D", "#DAD7C8", "#FFFDF0"],
    stone: ["#7D8385", "#B9C2C4", "#F2FCFF"],
    pewter: ["#82858C", "#BEC2CC", "#F2F6FF"],
    pink_marshmallow: ["#CE8992", "#FFD4E5", "#FFE7EF"],
    paradise_green: ["#62B191", "#9AFFBB", "#D9F9B0"],
  },
  face: {
    chill: [],
    smirk: [],
    smile: [],
    blasé: [],
    wholesome: [],
  },
  clothes: {
    ace_cardigan: ["#FDFBF0", "#E4E3D9", "#FF3837"],
    "army_t-shirt": ["#FDFBF0", "#E4E3D9", "#EEF1F1"],
    banana_hazmat: ["#FFD537", "#E3A934", "#FF3837"],
    banana_puffer: ["#FFD537", "#E3A934", "#FFEC6F"],
    baseball_hoodie: ["#FDFBF0", "#5B7FF9", "#3A4FA3"],
    basketball_jersey: ["#D60016", "#FFFFFF", "#FF6E74"],
    "windbreaker_(blackout)": ["#282828", "#1F1F1F", "#484848"],
    black_puff_jacket: ["#262626", "#1A191B", "#FAF9F0"],
    classic_suit: ["#1F1F1F", "#161616", "#FEFDF4"],
    black_tracksuit: ["#262627", "#FFE098", "#E0E5E8"],
    blue_sky_hoodie: ["#3B51A3", "#5C7FF8", "#FFFFFF"],
    rugby_shirt: ["#FF3A5B", "#5C7FF8", "#FFFFFF"],
    blue_headphones: ["#FFFFFF", "#5B7FF9", "#D6D6D6"],
    sunday_shirt: ["#F9F7E3", "#D0CEB5", "#28996B"],
    cashmere_turtleneck: ["#D6C4A0", "#9E9073", "#FAE7BF"],
    cherry_puffer: ["#D60016", "#FF0021", "#FF6E74"],
    summer_shirt: ["#F9F7E3", "#D0CEB5", "#FFFFFF"],
    cream_flannel: ["#FDFBF0", "#E4E3D9", "#FFFFFF"],
    marshmallow_puffer: ["#FDFBF0", "#E4E3D9", "#FFFFFF"],
    thrifted_denim_jacket: ["#434542", "#363835", "#CCA16C"],
    "c.r.e.a.m._hoodie": ["#F9F0E6", "#D0C8BF", "#FFFFFF"],
    nice_overalls: ["#63698C", "#2E3143", "#FF3F3F"],
    fast_jacket: ["#D60016", "#FF0021", "#DCC59A"],
    angler_vest: ["#E7E0CD", "#FFFEF4", "#FEDE97"],
    football_jersey: ["#282829", "#1C1C1C", "#FFFFFF"],
    bear_coat: ["#2B201C", "#FEDE97", "#493632"],
    happy_hoodie: ["#FFD537", "#E3A934", "#FFFFFF"],
    miracle_jersey: ["#FFFAE6", "#3E4161", "#FF0023"],
    stolen_hotel_robe: ["#FAF8E6", "#DACDBA", "#FEDE97"],
    jacksonhole_jacket: ["#493522", "#7C6652", "#674A33"],
    mcy00ts_polo: ["#D60016", "#FF0021", "#FFFFFF"],
    mechanic: ["#FDFBF0", "#D7F6FF", "#FF5A47"],
    blob_suit: ["#5B7FF9", "#FFD537", "#FF4737"],
    varsity_jacket: ["#5C7FF8", "#FFFFFF", "#3B51A3"],
    orange_hoodie: ["#FF8623", "#CB501B", "#FFA933"],
    "rare_t-shirt": ["#FFFFFF", "#CBC9C9", "#000000"],
    the_hoodie: ["#262626", "#1A191B", "#FFFFFF"],
    professor: ["#4B3725", "#FDF9E5", "#262016"],
    none: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
    "billionaire_zip-up": ["#212827", "#191F1F", "#FFFFF2"],
  },
  head: {
    "24k_gold_shoey_guzzler": ["#EFAB6E", "#FEDE97", "#F6FFC2"],
    backwards_snapback: ["#FAF8E6", "#DCD3BE", "#D2D1C1"],
    undercut: ["#755A44", "#533F30", "#2A221B"],
    headphones: ["#414340", "#FFD24A", "#292929"],
    big_cowboy_hat: ["#4C331E", "#FFDE8C", "#B07D55"],
    "beanie_(blackout)": ["#414141", "#FF7900", "#242523"],
    minimalist_camo_cap: ["#FF7839", "#FDFBF0", "#F9F2D9"],
    champ_hat: ["#3F3F3F", "#FFDF8C", "#262626"],
    chill_hat: ["#FFFFF1", "#E5E4D9", "#FFFFFF"],
    color_block_halo: ["#FF8727", "#43DC9E", "#5C7FF8"],
    cool_beanie: ["#5B7FF9", "#3A4FA3", "#A7B7EE"],
    bucket_hat: ["#E4E3D9", "#FF0023", "#FDFBF0"],
    down_bad_snapback: ["#009c66", "#ffffff", "#ff0026"],
    frank_headband: ["#FFFFE1", "#F8F7F1", "#FF0023"],
    free_snapback: ["#414340", "#343633", "#111111"],
    fuzzy_banana_bucket_hat: ["#FFD537", "#E3A934", "#FFED6F"],
    happy_hat: ["#4671DF", "#FFD946", "#001E85"],
    skinny_headband: ["#745942", "#FFFFFF", "#43352A"],
    mcy00ts_visor: ["#FF0000", "#ECECEC", "#D60016"],
    money_bands: ["#FFDF97", "#9BE49D", "#FF0023"],
    mullet: ["#41362B", "#705A45", "#000000"],
    banana_beanie: ["#FFD537", "#E3A934", "#FFED6F"],
    orange_beanie: ["#FFA835", "#FF8727", "#C95020"],
    pyrite_crown: ["#EFAB6E", "#FEDE97", "#F6FFC2"],
    flame_hat: ["#FF0023", "#FDFBF0", "#E4E3D9"],
    helmet: ["#FF0023", "#C32929", "#E6CC9F"],
    red_horns: ["#FF0000", "#D40000", "#FF4848"],
    rockstar_hair: ["#D5BB85", "#FFF9C2", "#FFD565"],
    skull_trucker_hat: ["#5B7FF9", "#FFD537", "#FF3837"],
    spiky_hair: ["#FFFFE1", "#B9B9A0", "#2F2F2F"],
    truck_trucker: ["#FDFBF0", "#E4E3D9", "#000000"],
    w_visor: ["#FAF8E4", "#D1CFB5", "#FFFFFF"],
    windswept_hair: ["#DFDFC5", "#A0A08E", "#FFFFE3"],
    none: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
  },
  eyewear: {
    radiohead_squares: ["#FF5C52", "#8698D7", "#FF8452"],
    pollocks: ["#FFFFFF", "#FE828A", "#FFD537"],
    banksy_goggles: ["#434343", "#5E5E5E", "#FF4737"],
    grand_budapest_shades: ["#FAB1C5", "#FF96BD", "#5DA2C3"],
    last_supper_squares: ["#627B68", "#FAF6DC", "#73652F"],
    hockney_squares: ["#16C265", "#5B7FF9", "#F74358"],
    "6d_glasses": ["#F5F4E4", "#42DD9D", "#FF0000"],
    abbey_roads: ["#FFFFFF", "#525252", "#B8B8B8"],
    rembrandt_goggles: ["#EBE3D1", "#FEFEFE", "#EFE9E9"],
    lensless_glasses: ["#434542", "#323431", "#777B75"],
    "rounded_squares_(blackout)": ["#252B2A", "#434542", "#777B75"],
    blue_light_glasses: ["#FFFEFE", "#C5C4C4", "#989898"],
    trapezoids: ["#373737", "#262626", "#FFFCF3"],
    cobain_goggles: ["#FAF8E6", "#C4C3B5", "#434542"],
    mona_lisas: ["#78DEB7", "#FF6165", "#FFE45F"],
    dieter_rams: ["#A29F8A", "#F1ECD1", "#FF4823"],
    "24k_gold_blocks": ["#FDDD98", "#EEAC6E", "#F6FCBD"],
    golden_hour_frames: ["#FFDF19", "#FFC22E", "#FFCD00"],
    green_bricks: ["#009C66", "#006C45", "#00B77F"],
    heatwaves: ["#4665CB", "#FF0000", "#35CA88"],
    ive_unibody: ["#CBCBCB", "#B0B0B0", "#3F3F3F"],
    matisse_frames: ["#3076EC", "#F5F8D1", "#215EC5"],
    mondrian_squares: ["#FF1600", "#3082FF", "#FFE900"],
    monet_squares: ["#78DEB7", "#FF6165", "#FFE45F"],
    melrose_bricks: ["#FFC9D2", "#FCA7B4", "#F197A5"],
    nouns: ["#F62E2E", "#C32929", "#FFFFFF"],
    orange_soda_shades: ["#FF7200", "#F02F00", "#FF5050"],
    none: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
    retro_apple: ["#FFFCF0", "#943B97", "#61BC46"],
    "windsors_(blackout)": ["#4F4F4F", "#101010", "#CBCBCB"],
    "tints_(blue)": ["#5C7FF8", "#3B51A3", "#A9C9FD"],
    "tints_(red)": ["#FF0019", "#BA0018", "#FFB09C"],
    "tints_(yellow)": ["#FFD338", "#E2A937", "#FFF49C"],
    sunflowers: ["#FFD834", "#E3A934", "#4A4A4A"],
    cali_sunset: ["#FFD537", "#FF7961", "#FFBFBE"],
    "aviators_(blackout)": ["#222523", "#CCA16C", "#8E5B00"],
    van_goghs: ["#5979FF", "#FBB334", "#F6FF65"],
    warhols: ["#8BF7F7", "#FF3C29", "#59FF83"],
    "wayfarers_(blackout)": ["#464646", "#181818", "#FEDE97"],
    "win-win_shades": ["#363636", "#989898", "#E4E4E4"],
  },
  "1/1": {
    none: [],
    abstract_y00t: [],
    aqua_y00t: [],
    astroy00t: [],
    balloon_y00t: [],
    cloud_y00t: [],
    coded_y00t: [],
    colored_pencil_y00t: [],
    deconstructed_y00t: [],
    doodle_y00t: [],
    elder_y00t: [],
    FACIAL_EXPRESSIONS_STUDY: [],
    "FRANK'S_SCRIBBLE": [],
    ghosted_y00t: [],
    giga_y00t: [],
    golden_y00t: [],
    ice_cream_king_y00t: [],
    mermaid_y00t: [],
    "mini(dino)": [],
    "mini(whale)": [],
    MODELING_CLAY_SCULPTURE: [],
    monochromatic_zombie_y00t: [],
    "mt.y00topia": [],
    phantom_y00t: [],
    pixel_y00t: [],
    pixie_y00t: [],
    "plant-based_y00t": [],
    queen_y00t: [],
    renaissance_y00t: [],
    sk8r_y00t: [],
    skeleton_y00t: [],
    slime_y00t: [],
    sludge_y00t: [],
    underworld_y00t: [],
    vintage_y00t: [],
    weird_y00t: [],
    wolf_of_y00tstreet: [],
    "x-ray_y00t": [],
    y00t_on_train: [],
    y00t_on_wall: [],
    "y00ts_&_crafts": [],
  },
};

export default DEFAULTS;
