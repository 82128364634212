import React, {
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Link from "next/link";
import { motion } from "framer-motion";
import Mask from "public/shared/masks/hexagon.png";
import {
  Button,
  CircularProgress,
  Drawer,
  Modal,
  Tooltip,
} from "@dustlabs/web/core";
import {
  CloseIcon,
  DownloadIcon,
  GalleryAddIcon,
  GalleryIcon,
  HexagonIcon,
  InfoIcon,
  SquareIcon,
} from "@dustlabs/web/icons";
import { NftName, OptionToggle } from "@deid/components";
import { AttributeList, ImageShimmer } from "@shared/components";
import { NftMetadata } from "@shared/types";
import { useWindowSize } from "@dustlabs/web/hooks";
import { useBreakpoint } from "@shared/hooks";
import { isS2, isS3, useApplyMetadata } from "@deid/helpers";
import { METADATA_URL } from "@deid/constants";
import { Preview } from "@deid/types";

interface Props extends HTMLAttributes<HTMLDivElement> {
  nftMetadata: NftMetadata;
  callback?: () => Promise<void>;
  isBtc?: boolean;
}

const NftDetailModal: FC<Props> = ({
  nftMetadata,
  className,
  callback,
  isBtc,
  ...componentProps
}: Props) => {
  const isWeb = useBreakpoint("2xl");
  const [_, winHeight] = useWindowSize();
  const showModal = useMemo(() => winHeight > 982 || isWeb, [winHeight]);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const [season, setSeason] = useState<number>(0);
  const seasonOptions = useMemo(() => ["S I", "S II", "S III"], []);

  const [perspective, setPerspective] = useState<number>(0);
  const perspectiveOptions = useMemo(
    () => [
      <GalleryIcon
        width={20}
        height={20}
        className={`${
          perspective === 0 ? "fill-darkFontPrimary" : "fill-lightFontPrimary"
        }`}
      />,
      <GalleryAddIcon
        width={20}
        height={20}
        className={`${
          perspective === 1 ? "fill-darkFontPrimary" : "fill-lightFontPrimary"
        }`}
      />,
    ],
    [perspective]
  );

  const [preview, setPreview] = useState<Preview>(Preview.DEFAULT);
  const previewOptions = useMemo(
    () =>
      isBtc
        ? [
            <SquareIcon
              width={32}
              height={32}
              rectAttributes={{
                className: `${
                  preview === Preview.DEFAULT
                    ? "stroke-darkFontPrimary"
                    : "stroke-lightFontPrimary"
                }`,
              }}
            />,
            <SquareIcon
              width={32}
              height={32}
              rectAttributes={{
                className: `!stroke-bitcoin`,
              }}
            />,
          ]
        : [
            <SquareIcon
              width={32}
              height={32}
              rectAttributes={{
                className: `${
                  preview === Preview.DEFAULT
                    ? "stroke-darkFontPrimary"
                    : "stroke-lightFontPrimary"
                }`,
              }}
            />,
            <HexagonIcon
              width={32}
              height={32}
              pathAttributes={{
                className: `${
                  preview === Preview.HEXAGON
                    ? "stroke-darkFontPrimary"
                    : "stroke-lightFontPrimary"
                }`,
              }}
            />,
          ],
    [isBtc, preview]
  );

  const currentImageSeason = useMemo(() => {
    if (nftMetadata?.image.includes("s3")) {
      return 2;
    } else if (nftMetadata?.image.includes("dead")) {
      return 1;
    } else {
      return 0;
    }
  }, [nftMetadata]);

  const currentImagePerspective = useMemo(() => {
    if (nftMetadata?.image.includes("zoomed")) {
      return 1;
    } else {
      return 0;
    }
  }, [nftMetadata]);

  useEffect(() => {
    if (isBtc) {
      setSeason(1);
      setPerspective(0);
    } else {
      setSeason(currentImageSeason);
      setPerspective(currentImagePerspective);
    }

    setPreview(Preview.DEFAULT);
  }, [nftMetadata, drawerOpen]);

  const nftImage = useMemo(() => {
    switch (season) {
      case 0:
        switch (perspective) {
          case 1:
            return `${METADATA_URL}${nftMetadata.id}-zoomed.png`;
          default:
          case 0:
            return `${METADATA_URL}${nftMetadata.id}.png`;
        }
      case 1:
        if (isS2(nftMetadata) || isS3(nftMetadata)) {
          switch (perspective) {
            case 1:
              return `${METADATA_URL}${nftMetadata.id}-dead-zoomed.png`;
            default:
            case 0:
              return `${METADATA_URL}${nftMetadata.id}-dead.png`;
          }
        } else {
          return "/degods/not-transcended-layered.png";
        }
      case 2:
      default:
        if (isS3(nftMetadata)) {
          switch (perspective) {
            case 1:
              return `${METADATA_URL}${nftMetadata.id}-s3-male-zoomed.png`;
            default:
            case 0:
              return `${METADATA_URL}${nftMetadata.id}-s3-male.png`;
          }
        } else {
          return "/degods/not-transcended-layered.png";
        }
    }
  }, [season, perspective, nftMetadata]);

  const isDisabled = useMemo(() => {
    const currentImage = nftMetadata?.image;

    if (!currentImage) return true;

    const currentSeason = currentImage.includes("s3")
      ? 2
      : currentImage.includes("dead")
      ? 1
      : 0;
    return currentSeason === season;
  }, [nftMetadata, season]);

  const [hexagonCrop, setHexagonCrop] = useState<string>("");
  const [cropLoading, setCropLoading] = useState<boolean>(false);

  const crop = useCallback(() => {
    setCropLoading(true);
    const offScreenCanvas = document.createElement("canvas");
    offScreenCanvas.width = Mask.width;
    offScreenCanvas.height = Mask.height;
    var ctx = offScreenCanvas.getContext("2d");
    if (ctx) {
      var imageObj1 = new window.Image();
      imageObj1.src = Mask.src;
      imageObj1.onload = () => {
        const selectedImage = new window.Image();
        selectedImage.src = nftImage;
        selectedImage.width = Mask.width;
        selectedImage.height = Mask.height;
        selectedImage.crossOrigin = "anonymous";

        selectedImage.onload = () => {
          if (ctx) {
            ctx.drawImage(imageObj1, 0, 0);
            ctx.globalCompositeOperation = "source-in";
            ctx.drawImage(selectedImage, 0, 0, Mask.width, Mask.height);
            setHexagonCrop(offScreenCanvas.toDataURL("image/png", 1));
            setCropLoading(false);
          }
        };
      };
    }
  }, [nftImage]);

  useEffect(() => {
    if (preview === Preview.HEXAGON && nftImage !== "") {
      isBtc
        ? setHexagonCrop(
            `https://static.degods.com/ordinals/${nftMetadata.id}.png`
          )
        : crop();
    }
  }, [preview, crop, nftImage]);

  const { mutateAsync: applyMetadata, isLoading: isLoadingApplyMetadata } =
    useApplyMetadata(callback);

  const handleApplyMetadata = async () => {
    await applyMetadata({
      tokenId: nftMetadata.id,
      season: season + 1,
      perspective: 0, // disable zoomed for now
    });
  };

  const renderContent = () => (
    <div className="w-full flex flex-col lg:flex-row lg:justify-center gap-5 heightSmall:gap-5 md:gap-10 py-8 heightSmall:pb-0 heightSmall:pt-5 md:py-12 px-3 lg:px-16 overflow-auto rounded-t-2xl lg:rounded-none scrollbar-none">
      <NftName
        nftMetadata={nftMetadata}
        className="flex lg:hidden"
        isBtc={isBtc}
      />
      <div className="flex flex-col gap-3 heightSmall:gap-3 md:gap-6">
        <div className="w-full aspect-square relative">
          {preview === Preview.HEXAGON && hexagonCrop ? (
            <ImageShimmer
              src={cropLoading ? "" : hexagonCrop}
              fill
              alt={`${nftMetadata.id}`}
            />
          ) : (
            <ImageShimmer src={nftImage} fill alt={`${nftMetadata.id}`} />
          )}
        </div>
        <div className="flex flex-col heightXs:flex-col md:flex-row gap-3 heightXs:gap-3 md:gap-6">
          <div className="flex-1">
            <div className="hidden md:block text-center text-fontSecondary text-xs mb-1">
              Season
            </div>
            <OptionToggle
              value={season}
              handleChange={(newValue) => setSeason(newValue as number)}
              options={seasonOptions}
              omit={
                !isS3(nftMetadata) ? (!isS2(nftMetadata) ? [1, 2] : [2]) : []
              }
              highlight={!isBtc ? currentImageSeason : undefined}
              disabled={isBtc && preview === Preview.HEXAGON}
            />
          </div>
          <div className="flex gap-3 heightXs:gap-3 md:gap-6 flex-1">
            <div className="flex-1">
              <div className="relative hidden md:flex justify-center items-center gap-1 text-center text-fontSecondary text-xs mb-1">
                <span>Perspective</span>
                <Tooltip
                    content={[
                      "These perspective options do not affect the image displayed on the NFT.",
                    ]}
                    className="bg-lightBackground border border-lightBorder top-7 md:top-0 
              -left-[250px] lg:-left-[160px] min-h-[70px] w-[320px] p-2 rounded-lg text-xs"
                  >
                    <InfoIcon
                      width={12}
                      height={12}
                      className="fill-transparent [&>path]:stroke-fontSecondary"
                    />
                  </Tooltip>
              </div>
              <OptionToggle
                value={perspective}
                handleChange={(newValue) => setPerspective(newValue as number)}
                options={perspectiveOptions}
                highlight={!isBtc ? currentImagePerspective : undefined}
                disabled={isBtc && preview === Preview.HEXAGON}
              />
            </div>

            {isBtc ? (
              <div className="flex-1">
                <div className="hidden md:block text-center text-fontSecondary text-xs mb-1">
                  BitGod
                </div>
                <OptionToggle
                  value={preview === Preview.HEXAGON ? 1 : 0}
                  handleChange={(newValue) =>
                    setPreview(
                      newValue === 1 ? Preview.HEXAGON : Preview.DEFAULT
                    )
                  }
                  options={previewOptions}
                  disabled={season === 0 || perspective === 1}
                />
              </div>
            ) : (
              <div className="flex-1">
                <div className="relative hidden md:flex justify-center items-center gap-1 text-center text-fontSecondary text-xs mb-1">
                  <span>Preview</span>
                  <Tooltip
                    content={[
                      "These preview options do not affect the image displayed on the NFT, instead are meant to show you what it will look like in the NFT vs Twitter Hexagon.",
                    ]}
                    className="bg-lightBackground border border-lightBorder top-7 md:top-0 
              -left-[250px] lg:-left-[160px] min-h-[70px] w-[320px] p-2 rounded-lg text-xs"
                  >
                    <InfoIcon
                      width={12}
                      height={12}
                      className="fill-transparent [&>path]:stroke-fontSecondary"
                    />
                  </Tooltip>
                </div>
                <OptionToggle
                  value={preview === Preview.HEXAGON ? 1 : 0}
                  handleChange={(newValue) =>
                    setPreview(
                      newValue === 1 ? Preview.HEXAGON : Preview.DEFAULT
                    )
                  }
                  options={previewOptions}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col heightSmall:flex-row gap-3">
          {!isBtc && (
            <Button
              full
              onClick={handleApplyMetadata}
              disabled={isDisabled}
              className="h-12 bg-primary flex justify-center items-center gap-2 font-bold heightSmall:text-xs text-sm text-darkFontPrimary uppercase rounded-lg"
            >
              {isLoadingApplyMetadata ? (
                <>
                  <CircularProgress className="first:[&_path]:fill-none fill-darkFontPrimary" />
                  <div className="heightSmall:text-[9px]">
                    Setting new image...
                  </div>
                </>
              ) : (
                "Apply Metadata"
              )}
            </Button>
          )}

          {nftImage !== "/degods/not-transcended-layered.png" &&
            nftImage !== "/degods/not-s3-layered.png" && (
              <Link
                className="w-full h-12 flex flex-row justify-center items-center border border-lightFontPrimary rounded-lg"
                href={
                  isBtc && preview === Preview.HEXAGON ? hexagonCrop : nftImage
                }
                rel="noreferrer"
                target="_blank"
              >
                <div className="flex flex-row text-center justify-center items-center gap-1">
                  <DownloadIcon className="w-4 h-4 heightSmall:w-3 heightSmall:h-3 heightSmall:mt-0 fill-lightFontPrimary mt-[2px]" />

                  <h3 className="heightSmall:text-[10px] text-sm font-bold uppercase">
                    Download Image
                  </h3>
                </div>
              </Link>
            )}
        </div>
      </div>
      <div className="flex-1 lg:max-w-[560px] flex flex-col gap-3">
        <NftName
          nftMetadata={nftMetadata}
          className="hidden lg:flex"
          isBtc={isBtc}
        />
        <AttributeList item={nftMetadata} overview />
        {!isBtc && (
          <div className="flex flex-col gap-1 pt-3 lg:pt-16 pl-1">
            <div className="text-sm font-bold">How it works:</div>
            <div className="text-sm text-fontSecondary">
              Toggle your DeGod between different Seasons and apply the changes
              on-chain. The selected Season and crop will become the default
              view for your NFT on all marketplaces.
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div
        className={`absolute w-full h-[276px] z-10 cursor-pointer ${className}`}
        onClick={handleOpen}
        {...componentProps}
      />

      {showModal ? (
        <Modal
          initial={{ opacity: 0, y: "50vh" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "50vh" }}
          transition={{ duration: 0.35 }}
          isOpen={drawerOpen}
          handleClose={handleClose}
          className="max-w-[1283px] !w-[95vw] max-h-[85vh] !h-fit !rounded-xl bg-lightModal !p-0 flex"
          closeIconProps={{ className: "hidden" }}
          backdropProps={{
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity: 0 },
            transition: { duration: 0.35 },
            className: "bg-opacity-50 bg-[#000]",
          }}
        >
          <motion.div
            whileTap={{ scale: 0.9 }}
            transition={{ duration: 0.2 }}
            className="absolute -top-3 -right-3 cursor-pointer z-30 w-8 h-8 !rounded-full bg-[#19191C] flex justify-center items-center"
            onClick={handleClose}
          >
            <CloseIcon width={10} height={10} className="fill-[#D2D2D2]" />
          </motion.div>

          {renderContent()}
        </Modal>
      ) : (
        <Drawer
          anchor="bottom"
          isOpen={drawerOpen}
          handleClose={handleClose}
          className="relative max-h-[85vh] heightSmall:max-h-[90vh] max-w-[1283px] md:!w-[95vw] !h-fit bg-lightModal 
          flex justify-center justify-self-center rounded-t-2xl !pt-0 !lg:pt-3 pb-0 lg:pb-5"
          closeIconProps={{
            className: "hidden",
          }}
          backdropProps={{
            className: "bg-opacity-50 bg-[#000]",
          }}
        >
          <div className="absolute w-full -top-4 md:-top-3 flex justify-center md:justify-end items-center z-20">
            <Button
              text
              onClick={handleClose}
              className="relative md:absolute w-8 h-8 right-auto md:-right-2 !rounded-full bg-[#19191C] flex justify-center items-center"
            >
              <CloseIcon width={10} height={10} className="fill-[#D2D2D2]" />
            </Button>
          </div>
          {renderContent()}
        </Drawer>
      )}
    </>
  );
};

export default NftDetailModal;
