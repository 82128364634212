import React, { FC, useEffect, useState } from "react";
import Image from "next/image";
import { AnimatePresence, motion } from "framer-motion";
import { useAccount } from "wagmi";
import { SignInButton } from "@deid/components";
import { ConnectButton } from "@shared/components";
import { GLOBAL_CONFIG } from "@shared/constants";
import { DeGodsLogo } from "@dustlabs/web";

interface Props {
  callback?: () => void;
}

const LandingView: FC<Props> = ({ callback }: Props) => {
  const { isConnected } = useAccount();
  const [randomImage, setRandomImage] = useState<number>(
    Math.floor(Math.random() * 5) + 1
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRandomImage(Math.floor(Math.random() * 5) + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      {...GLOBAL_CONFIG.OPACITY_ANIMATION}
      className="h-full flex flex-col items-center"
    >
      <div className="flex flex-col w-full h-full px-6 md:px-0 max-w-[1400px] overflow-hidden">
        <div className="w-full h-full flex flex-col md:flex-row justify-between gap-10">
          <div className="flex flex-col items-center md:items-start gap-5 md:gap-12 flex-1">
            <div className="h-12 md:h-[25vh] max-h-[430px] hidden md:block">
              <Image
                src="/degods/landing/delabs-icon.png"
                width={72}
                height={30}
                className="mt-12"
                alt="delabs"
              />
            </div>
            <DeGodsLogo
              className="w-full max-w-[269px] md:max-w-[500px] mt-24 md:mt-0"
              variant="full"
            />
            {/* <div className="flex flex-col w-[191px]">
              <ConnectButton
                variant="small"
                className="[&_.profileButton]:w-full [&_.profileButtonRoot]:w-full [&_.profileButtonMenuRoot]:w-full [&_.profileButtonMenuRoot]:left-3 md:[&_.profileButtonMenuRoot]:left-0"
              />
              {isConnected ? (
                <SignInButton callback={callback} />
              ) : (
                <div className="h-[38px] mt-3" />
              )}
            </div> */}
            <div className="h-12 md:h-[15vh] max-h-[330px] hidden md:flex items-end mt-[138px] mb-8">
              <Image
                src="/degods/landing/delabs-footer.png"
                width={500}
                height={60}
                alt="designed and crafted in LA"
              />
            </div>
          </div>
          <div className="h-fit hidden md:flex items-center gap-3 overflow-hidden -mt-8">
            <div
              className="relative max-h-[1200px] h-[100vh]"
              style={{ aspectRatio: 0.539 }}
            >
              <Image src="/degods/landing/cards.png" fill alt="cards" />
            </div>
          </div>

          <div className="w-full flex md:hidden justify-center -mb-36">
            <div className="relative w-full" style={{ aspectRatio: 0.755 }}>
              <Image src="/degods/landing/cards-mobile.png" fill alt="cards" />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default LandingView;
