import React from "react";
import { Y00tsLogo } from "@dustlabs/web/icons";
import Image from "next/image";
import { LoadingDots, OpenseaButton } from "@shared/components";
import { GLOBAL_CONFIG } from "@shared/constants";

const Y00tsIIView = () => {
  return (
    <div className="relative mb-56">
      <div
        className="w-full flex flex-col gap-5 items-center cursor-pointer"
        onClick={() =>
          window.open(
            GLOBAL_CONFIG.PARENT_SITE_URL,
            "_blank",
            "noopener noreferrer"
          )
        }
      >
        <Y00tsLogo
          variant="season2"
          className="w-[294px] h-[234px] md:w-[392px] md:h-[312px]"
        />
        <LoadingDots className="w-[145px] text-2xl ml-8 -mt-16 md:-mt-24">
          loading
        </LoadingDots>
      </div>

      <div className="absolute w-full flex justify-center -bottom-36">
        <OpenseaButton />
      </div>

      <div className="fixed bottom-0 right-0 w-full flex justify-end pointer-events-none">
        <Image
          src="/y00ts/y00ts-teaser.png"
          width={937}
          height={512}
          alt="teaser"
        />
      </div>
    </div>
  );
};

export default Y00tsIIView;
