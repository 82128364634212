import React from "react";
import Spline from "@splinetool/react-spline";
import { BlurButton } from "@shared/components";
import Image from "next/image";
import Link from "next/link";
import DeGodsFAQ from "shared/components/molecules/DeGodsFAQ";

const DeGodsIIIView = () => {
  return (
    <div className="w-full min-h-screen bg-black mt-36">
      <div className="relative h-screen -mb-20 overflow-hidden">
        <div className="absolute z-10 flex flex-col items-center justify-center w-full h-screen space-y-8 text-white md:justify-end md:-top-28">
          <Link
            href="/downgrade"
            className={
              "flex space-x-2 hover:scale-105 transition-all duration-150 mb-3"
            }
          >
            <p className="text-xl font-bold md:text-2xl">
              Enter the new season
            </p>
            <Image
              src="/degods/arrow.svg"
              alt="Arrow"
              width={113}
              height={22}
            />
          </Link>

          <BlurButton />

          <div className="uppercase text-gray-dark">
            scroll for FAQ
            <Image
              src="/degods/caret.svg"
              alt="Caret"
              width={12}
              height={6}
              className="mx-auto mt-3 animate-bounce"
            />
          </div>
        </div>

        <Spline
          scene="/degods/SIII.splinecode"
          className="absolute left-0 z-0 h-screen -top-64 md:-top-40 -bo"
        />
      </div>

      <div className="bg-black md:pt-36">
        <div className="max-w-xl px-5 mx-auto">
          <DeGodsFAQ />
        </div>
      </div>
    </div>
  );
};

export default DeGodsIIIView;
