import axios from "axios";
import toast from "react-hot-toast";
import { UseQueryOptions, useMutation, useQueries } from "react-query";
import { useDeId } from "@dustlabs/profiles/core";
import { AttributeMetadata, NftMetadata } from "@shared/types";
import { METADATA_API } from "@deid/constants";
import { ORDINALS_MAP } from "@explorer/constants";
import { useMemo } from "react";
import { ProfileHeldCollection } from "@dustlabs/profiles/types";

/**
 * Hook that fetches owned NFT tokenIds across all user's wallet addresses.
 *
 * @returns React useQueries response object.
 */
export const useNftMetadata = () => {
  const { holdings } = useDeId();

  const evmHoldings = useMemo(
    () =>
      holdings
        .find(
          (heldCollection: ProfileHeldCollection) =>
            heldCollection.contract === process.env.NEXT_PUBLIC_NFT_CONTRACT
        )
        ?.tokens.map((token) => token.tokenId) ?? [],
    [holdings]
  );

  const fetchNftMetadata = async (metadataUrl: string) => {
    const response = await axios.get(metadataUrl);
    const metadata = response.data;
    metadata["id"] = Number(metadata.name.split("#")[1] - 1);
    return metadata;
  };

  const queries: UseQueryOptions<NftMetadata, Error>[] =
    evmHoldings?.map((tokenId: number) => ({
      queryKey: ["getTokenMetadata", tokenId],
      queryFn: () =>
        fetchNftMetadata(
          process.env.NEXT_PUBLIC_METADATA_URL + tokenId.toString() + ".json"
        ),
      enabled: evmHoldings.length > 0,
    })) ?? [];

  return useQueries(queries);
};

/**
 * Hook that fetches owned ordinals tokenIds across all user's wallet addresses.
 *
 * @returns React useQueries response object.
 */
export const useOrdinalsMetadata = () => {
  const { holdings } = useDeId();

  const btcHoldings = useMemo(
    () =>
      holdings
        .find(
          (heldCollection: ProfileHeldCollection) =>
            heldCollection.contract === "BTC DeGods"
        )
        ?.tokens.map((token) => token.tokenId) ?? [],
    [holdings]
  );

  const fetchNftMetadata = async (metadataUrl: string) => {
    const response = await axios.get(metadataUrl);
    const metadata = response.data;
    metadata["id"] = Number(metadata.name.split("#")[1] - 1);
    return metadata;
  };

  const queries: UseQueryOptions<NftMetadata, Error>[] =
    btcHoldings?.map((inscriptionNumber: number) => {
      const tokenId =
        (ORDINALS_MAP.find(
          (current) => current["Inscription Number"] === inscriptionNumber
        )?.["DeGod Id"] ?? 0) - 1;

      return {
        queryKey: ["getTokenMetadata", tokenId],
        queryFn: () =>
          fetchNftMetadata(
            process.env.NEXT_PUBLIC_METADATA_URL + `${tokenId}` + ".json"
          ),
        enabled: btcHoldings.length > 0,
      };
    }) ?? [];

  return useQueries(queries);
};

export interface ApplyMetadataPayload {
  tokenId: number;
  season: number;
  perspective: number;
}

export const useApplyMetadata = (callback?: () => Promise<void>) => {
  const { authAccess } = useDeId();

  const applyMetadata = authAccess(
    async ({ accessToken }, payload: ApplyMetadataPayload) => {
      try {
        const response = await axios.post(
          `${METADATA_API}/updatePFP`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (callback) await callback();
        toast.success(response.data.message);

        return response.data;
      } catch (error) {
        toast.error((error as Error).message);
        console.error(error);
      }
    }
  );

  return useMutation(["handleApplyMetadata"], applyMetadata);
};

export const isS3 = (nftMetadata: NftMetadata) =>
  nftMetadata?.attributes
    .find(
      (attributeMetadata: AttributeMetadata) =>
        attributeMetadata.trait_type === "version"
    )
    ?.value.startsWith("S3");

export const isS2 = (nftMetadata: NftMetadata) =>
  nftMetadata?.attributes
    .find(
      (attributeMetadata: AttributeMetadata) =>
        attributeMetadata.trait_type === "version"
    )
    ?.value.startsWith("DeadGod");
