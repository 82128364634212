import { ImageLoaderProps } from "next/image";

const normalizeSrc: (src: string) => string | number = (src: string) => {
  return src[0] === "/" ? process.env.NEXT_PUBLIC_URL + src : src;
};

const cloudflareLoader: (params: ImageLoaderProps) => string = ({
  src = "/shared/brands/dust.svg",
  width,
  quality,
}: ImageLoaderProps) => {
  const params = [`width=${width}`];
  if (quality) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join(",");
  return `https://powered.by.dustlabs.com/cdn-cgi/image/${paramsString}/${normalizeSrc(
    src
  )}`;
};

export default cloudflareLoader;