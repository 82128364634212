import React, { FC } from "react";
import { ImageShimmer } from "@shared/components";
import { NftMetadata } from "@shared/types";
import { NftDetailModal } from "@deid/components";

interface Props {
  nftMetadata: NftMetadata;
  tag: string;
  callback?: () => Promise<void>;
  isBtc?: boolean;
}

const NftCard: FC<Props> = ({ nftMetadata, tag, callback, isBtc }: Props) => {
  return (
    <div className="group !bg-lightCard border border-lightBorder relative p-2 rounded-2xl">
      <NftDetailModal
        nftMetadata={nftMetadata}
        className="w-full h-full top-0 left-0"
        callback={callback}
        isBtc={isBtc}
      />

      <div className="relative w-full aspect-square overflow-clip rounded-lg">
        <ImageShimmer
          src={nftMetadata.image}
          fill
          alt={`${nftMetadata.id}`}
          className="group-hover:scale-110 transition duration-500"
        />
      </div>
      <div className="flex flex-col pt-2 px-3">
        <div className="flex justify-between gap-1">
          <div className="font-bold text-sm">{nftMetadata.name}</div>
          {nftMetadata.rank && (
            <div className="border border-fontSecondary rounded-full pt-[1px] px-2 text-xs text-fontSecondary">
              R: {nftMetadata.rank}
            </div>
          )}
        </div>

        <div className="font-bold text-xs text-fontSecondary">{tag}</div>
      </div>
    </div>
  );
};

export default NftCard;
