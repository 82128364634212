import type { NextPage } from "next";
import { ClientOnly, PageTemplate } from "@shared/components";
import { GLOBAL_CONFIG } from "@shared/constants";
import { ProjectConfig } from "@shared/types";
import {
  DeGodsLandingView,
  LandingView,
  DeGodsIIIView,
  Y00tsIIView,
} from "@landing/components";
import { LandingView as DeGodsLanding } from "@deid/components";
import { useWindowSize } from "@dustlabs/web/hooks";
import { useRouter } from "next/router";

const Landing: NextPage = () => {
  const router = useRouter();
  const { HEAD, ORG_NAME, SEASON } = GLOBAL_CONFIG as ProjectConfig;
  const [winWidth, winHeight] = useWindowSize();

  const getSeasonSkin = () => {
    switch (ORG_NAME) {
      case "y00ts":
        switch (SEASON) {
          case 2:
            return {
              forceCenter: true,
              landing: <Y00tsIIView />,
              footer: undefined,
            };
          case 1:
          default:
            return {
              forceCenter: false,
              landing: <LandingView />,
              footer: 1,
            };
        }

      case "DeGods":
      default:
        switch (SEASON) {
          case 3:
            return {
              forceCenter: false,
              landing: (
                <ClientOnly
                  className="w-full lg:pt-[77px] px-5 lg:px-24"
                  style={{
                    height: winWidth < 1024 ? winHeight - 73 : winHeight,
                  }}
                >
                  <DeGodsLanding callback={() => router.push("/holdings")} />
                </ClientOnly>
              ),
              footer: undefined,
            };
          case 2:
          case 1:
          default:
            return {
              forceCenter: false,
              landing: <DeGodsLandingView />,
              footer: undefined,
            };
        }
    }
  };

  const { forceCenter, landing, footer } = getSeasonSkin();

  return (
    <PageTemplate
      pageMeta={HEAD}
      path="/"
      forceCenter={forceCenter}
      footer={footer}
    >
      {landing}
    </PageTemplate>
  );
};

export default Landing;
