import { Disclosure, Transition } from "@headlessui/react";
import { AddIcon, CloseIcon } from "@dustlabs/web/icons";
import clsx from "clsx";
import Link from "next/link";

type Props = {
  className?: string;
};

export default function DeGodsFAQ(props: Props) {
  const { className } = props;
  return (
    <div className="py-20 md:py-42">
      <div
        className={clsx(
          className,
          "divide-y-[3px] divide-darkBorder space-y-12"
        )}
      >
        <h2 className="mb-2 text-2xl font-semibold text-center text-white uppercase font-offbit">
          Frequently asked questions
        </h2>

        <div className="w-full col-span-7">
          <div className="space-y-[24px]">
            <ul className="divide-y-[3px] divide-darkBorder">
              {QUESTIONS.map((q, i) => {
                const { question, answer } = q;
                return (
                  <Disclosure
                    as="div"
                    key={`question-` + i}
                    defaultOpen={false}
                    className="space-y-2"
                  >
                    {({ open }) => (
                      <>
                        <dt className="text-lg">
                          <Disclosure.Button className="flex items-start justify-between w-full py-5 text-left hover:bg-black-300 hover:bg-opacity-10">
                            <span className="font-bold text-white font-offbit">
                              {question}
                            </span>
                            <span className="flex items-center justify-center flex-shrink-0 ml-6 text-white w-7 h-7">
                              {open ? (
                                <CloseIcon className="w-3 h-3 fill-darkFontPrimary" />
                              ) : (
                                <AddIcon className="w-4 h-4 fill-darkFontPrimary" />
                              )}
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Transition
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel as="dd" className="pb-10 pr-12">
                            <p className="text-fontSecondary font-[500]">
                              {answer}
                            </p>
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

const QUESTIONS = [
  {
    question: "How do I buy a DeGod?",
    answer: (
      <>
        Visit{" "}
        <Link
          className="text-white hover:underline"
          target="_blank"
          rel="noopen nofollow"
          href="https://blur.io/collection/degods"
        >
          Blur
        </Link>{" "}
        or{" "}
        <Link
          className="text-white hover:underline"
          target="_blank"
          rel="noopen nofollow"
          href="https://opensea.io/collection/degods"
        >
          OpenSea
        </Link>{" "}
        and purchase your DeGod
      </>
    ),
  },
  {
    question: "How do I downgrade my DeGod?",
    answer: (
      <>
        Visit{" "}
        <Link
          className="text-white hover:underline"
          target="_blank"
          rel="noopen nofollow"
          href="https://degods.com/downgrade"
        >
          degods.com/downgrade
        </Link>{" "}
        and have 333 $DUST to downgrade your DeGod
      </>
    ),
  },
  {
    question: "Do I need to unstake my DeGod to downgrade it to Season 3?",
    answer: (
      <>
        No, you don’t need to unstake or stake your DeGod in order to downgrade
        the art.
      </>
    ),
  },
  {
    question: "How do I join the community?",
    answer: (
      <>
        Visit{" "}
        <Link
          className="text-white hover:underline"
          target="_blank"
          rel="noopen nofollow"
          href="https://de.xyz"
        >
          de.xyz
        </Link>{" "}
        to join the discord. Make sure to setup up your DeID by connecting your
        wallet to get full access to the discord.
      </>
    ),
  },
];
